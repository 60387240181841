import logo from './logo2.png';

function Home() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h2><i>Exposing the Overrated: From Eateries to Escapes!</i></h2>
        {/* <ul>
        <li>Cutting Through the Hype: Unmasking Overrated Eateries!</li>
        <li>Separating the Sizzle from the Substance: Expose Overrated Dining!</li>
        <li>Taste the Reality: Uncover Overrated Eats!</li>
        <li>Savoring Honesty: Debunking Overrated Dining!</li>
        <li>Dishing the Truth: Unveiling Overrated Gems Everywhere!</li>
        </ul> */}
        
        <h2>Coming soon</h2>
      </header>
    </div>
  );
}

export default Home;

import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Home from './Home.js';
import Login from './Login.js';
import Reset from './Reset';
import Dashboard from './Dashboard';
import Register from './Register';
import './App.css';
import { GoogleAuthProvider } from "firebase/auth";
const provider = new GoogleAuthProvider();

function App() {
  return (
    <div className="app">
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/reset" element={<Reset />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
      
    </div>
  );
}

export default App;
